.root {
  
}

/* Styles for StepperInput */
.stepperInputWrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--colorGrey200);
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;

  & button {
    font-size: 1.5rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  & input {
    width: 100%;
    text-align: center;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  /* Chrome, Safari, Edge, Opera */
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type=number] {
    -moz-appearance: textfield;
  }

  & button[disabled] {
    opacity: .1;
    cursor: not-allowed;
  }
}