
.root {
  align-items: center;
  display: flex;
  height: 100%;
}

.container {
  position: relative;
  line-height: 1;
}

.quantity {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 16px;
  min-height: 16px;
  aspect-ratio: 1;
  font-size: 10px;
  font-weight: var(--fontWeightBold);
  border-radius: 100%;
  color: var(--colorAttentionLight);
  background-color: var(--marketplaceColor);
  padding: 4px;
  transition: 0.125;
  transform: translate(50%,-25%);
  -webkit-transform: translate(50%,-25%);
}

.icon {
  width: 22px;
  height: 21px;

  & path {
    fill: var(--colorBlack);
  }
}